<template>
  <div class="mapbox">
    <div id="map"></div>
    <mapCheck :options="layerCheckOptions"></mapCheck>
    <!-- <measuringModule></measuringModule> -->
    <!-- 摄影 -->
    <!-- <div class="threeModel">
      <div
        v-if="showThreeTilesDialog && $route.name === 'Task'"
        @click="addModel"
      >
        <el-button type="info" round>倾斜摄影</el-button>
      </div>
      <div v-if="showImgDialog && $route.name === 'Task'" @click="addImg">
        <el-button type="info" round>正射影像</el-button>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { mask } from '@/utils/xzqh.js'
import mapCheck from './mapCheck.vue'
// import measuringModule from './measuringModule.vue'
import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import gdNew from '@/mock/gengdixin.json'

export default {
  data() {
    return {
      viewerModel: null,
      modelStatus: false,
      layerCheckOptions: [
        {
          layer: ['img-tiles', 'img-label-tiles'],
          label: '电子地图'
        },
        {
          layer: ['img-tdt-yx', 'img-tdt-yx-zj'],
          label: '影像地图'
        }
      ],
      map: null,
      showThreeTilesDialog: false,
      showImgDialog: false,
      imgServiceUrl: '',
      imgServiceLayer: '',
      geoWorkspaceId: '',
      threeTilesList: [],
      isZsMap: false,
      baseUrl: 'http://ai.milaihome.cn'
    }
  },
  // components: { mapCheck, measuringModule },
  components: {
    mapCheck
  },
  props: {
    // mapItem: {
    //   type: Object,
    //   default() {}
    // },
    mapIndex: {
      type: Number,
      default: 0
    },
    mapImage: {
      type: Object,
      default() {}
    }
  },

  mounted() {
    this.initMap()
    // 一个模拟模型  一直存在地图上
    // this.getThreeDModel()
  },
  watch: {
    // mapItem: {
    //   handler() {
    //     this.$nextTick(() => {
    //       this.initMap()
    //     })
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // '$store.state.threeTiles': {
    //   handler(val) {
    //     // console.log(val, 'model')
    //     if (val.geo3dtilesjson.length > 0) {
    //       this.showThreeTilesDialog = true
    //       this.threeTilesList = val.geo3dtilesjson
    //     }
    //     if (val.layergroupurl != '') {
    //       this.imgServiceUrl = val.layergroupurl
    //       this.imgServiceLayer = val.layergroup
    //       this.geoWorkspaceId = this.imgServiceLayer.split(':')[0]
    //       // this.showImgDialog = true
    //       this.addImg()
    //     }
    //   }
    // }
  },
  methods: {
    async getThreeDModel() {
      let res = await axios.get(
        `${this.baseUrl}/odm_pgsql/api/layers/${'test'}`
      )
      console.log(res)
      let tilesList = res.data[0].geo3dtilesjson
      let that = this
      Cesium.Ion.defaultAccessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NmQ5Mjk2MS00NTgwLTRlMzktYjdkZi04NDgxNTk5ZjZhNTYiLCJpZCI6NzAxOTYsImlhdCI6MTYzNDA4OTk0NX0.FovNKKGbLhXXcJgGOUjuczLPCCJ0YR-znZ-5wnrEvA4'
      const viewer = new Cesium.Viewer('map', {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: false, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: false, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: true, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        // scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        // 地形
        terrainProvider: new Cesium.createWorldTerrain({
          requestVertexNormals: true,
          requestWaterMask: true
        })
      })
      this.viewerModel = viewer

      // const promise = new Cesium.GeoJsonDataSource.load('./getBuild.json', {
      //   // stroke: Cesium.Color.HOTPINK,
      //   fill: Cesium.Color.WHITE.withAlpha(0.3),
      //   strokeWidth: 6,
      // });
      // promise.then((datasource) => {
      //   viewer.dataSources.add(datasource);   // 加载这个geojson资源
      //   const entities = datasource.entities.values;
      //   for (let index = 0; index < entities.length; index++) {
      //     const entity = entities[index];
      //     entity.polygon.heightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;  // 贴地
      //     entity.polygon.height = 0;  // 距地高度0米
      //     entity.polygon.extrudedHeightReference = Cesium.HeightReference.RELATIVE_TO_GROUND; //拉伸
      //     entity.polygon.extrudedHeight = entity.properties["height"]; // 拉伸高度
      //     entity.polygon.outline = false;
      //     entity.polygon.outlineColor = "rgba(0,0,0,0)";
      //   }
      // });
      //加载3DTiles
      function tilesetload() {
        let tilesetArry = []
        tilesList.forEach((item) => {
          tilesetArry.push(item.tileset)
        })

        for (let index = 0; index < tilesetArry.length; index++) {
          var tileset = new Cesium.Cesium3DTileset({
            // url: '/data/b3dm/Production_3.json',//本地瓦片数据
            url: `${this.baseUrl}/qingxie/${tilesetArry[index]}`, //在线瓦片数据
            show: true,
            skipLevelOfDetail: true,
            baseScreenSpaceError: 1024,
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: false,
            cullWithChildrenBounds: true
          })
          viewer.scene.primitives.add(tileset)
        }
        // 模型的位置信息
        var position = Cesium.Cartesian3.fromDegrees(
          119.8629330573541,
          30.398538911177653
        )
        //定位到模型的位置
        ;(async () => {
          viewer.camera.flyTo({
            destination: position,
            orientation: {
              heading: Cesium.Math.toRadians(0),
              pitch: Cesium.Math.toRadians(-90),
              roll: Cesium.Math.toRadians(0)
            },
            duration: 2
          })
          try {
            await tileset.readyPromise
            await viewer.zoomTo(tileset)
            // Apply the default style if it exists
            let extras = tileset.asset.extras
            if (
              Cesium.defined(extras) &&
              Cesium.defined(extras.ion) &&
              Cesium.defined(extras.ion.defaultStyle)
            ) {
              tileset.style = new Cesium.Cesium3DTileStyle(
                extras.ion.defaultStyle
              )
            }
          } catch (error) {
            console.log(error)
          }
        })()
      }
      tilesetload()
    },
    addImg() {
      // this.isZsMap = !this.isZsMap
      // if (this.isZsMap) {
      this.map.addLayer({
        id: 'geoserver-layer',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [
            `${this.baseUrl}/geoserver/geoserver/${this.geoWorkspaceId}/wms?service=WMS&version=1.1.0&request=GetMap&layers=${this.imgServiceLayer}&bbox={bbox-epsg-3857}&width=583&height=768&srs=EPSG:3857&styles=raster&format=image/png&TRANSPARENT=TRUE`
          ],
          leSize: 256
        },
        'source-layer': `${this.imgServiceLayer}`,
        paint: {}
      })
      // } else {
      // if (this.map.getLayer('geoserver-layer')) {
      //   this.map.removeLayer('geoserver-layer')
      //   this.map.removeSource('geoserver-layer')
      // }
      // }
    },
    addModel() {
      if (this.modelStatus) {
        this.qingchuModel()
      } else {
        this.qingxieMap()
      }
    },
    async qingxieMap() {
      this.modelStatus = !this.modelStatus
      // let res = await axios.get('api/data1.json')
      // console.log(res.data, '000data')
      let that = this
      Cesium.Ion.defaultAccessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NmQ5Mjk2MS00NTgwLTRlMzktYjdkZi04NDgxNTk5ZjZhNTYiLCJpZCI6NzAxOTYsImlhdCI6MTYzNDA4OTk0NX0.FovNKKGbLhXXcJgGOUjuczLPCCJ0YR-znZ-5wnrEvA4'
      const viewer = new Cesium.Viewer('map', {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: false, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: false, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: true, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        // scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        // 地形
        terrainProvider: new Cesium.createWorldTerrain({
          requestVertexNormals: true,
          requestWaterMask: true
        })
      })
      this.viewerModel = viewer

      // const promise = new Cesium.GeoJsonDataSource.load('./getBuild.json', {
      //   // stroke: Cesium.Color.HOTPINK,
      //   fill: Cesium.Color.WHITE.withAlpha(0.3),
      //   strokeWidth: 6,
      // });
      // promise.then((datasource) => {
      //   viewer.dataSources.add(datasource);   // 加载这个geojson资源
      //   const entities = datasource.entities.values;
      //   for (let index = 0; index < entities.length; index++) {
      //     const entity = entities[index];
      //     entity.polygon.heightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;  // 贴地
      //     entity.polygon.height = 0;  // 距地高度0米
      //     entity.polygon.extrudedHeightReference = Cesium.HeightReference.RELATIVE_TO_GROUND; //拉伸
      //     entity.polygon.extrudedHeight = entity.properties["height"]; // 拉伸高度
      //     entity.polygon.outline = false;
      //     entity.polygon.outlineColor = "rgba(0,0,0,0)";
      //   }
      // });
      //加载3DTiles
      function tilesetload() {
        let tilesetArry = []
        that.threeTilesList.forEach((item) => {
          tilesetArry.push(item.tileset)
        })

        for (let index = 0; index < tilesetArry.length; index++) {
          var tileset = new Cesium.Cesium3DTileset({
            // url: '/data/b3dm/Production_3.json',//本地瓦片数据
            url: `${this.baseUrl}/qingxie/${tilesetArry[index]}`, //在线瓦片数据
            show: true,
            skipLevelOfDetail: true,
            baseScreenSpaceError: 1024,
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: false,
            cullWithChildrenBounds: true
          })
          viewer.scene.primitives.add(tileset)
        }
        // 模型的位置信息
        var position = Cesium.Cartesian3.fromDegrees(
          120.102698638889,
          30.33519025
        )
        //定位到模型的位置
        ;(async () => {
          viewer.camera.flyTo({
            destination: position,
            orientation: {
              heading: Cesium.Math.toRadians(0),
              pitch: Cesium.Math.toRadians(-90),
              roll: Cesium.Math.toRadians(0)
            },
            duration: 2
          })
          try {
            await tileset.readyPromise
            await viewer.zoomTo(tileset)
            // Apply the default style if it exists
            let extras = tileset.asset.extras
            if (
              Cesium.defined(extras) &&
              Cesium.defined(extras.ion) &&
              Cesium.defined(extras.ion.defaultStyle)
            ) {
              tileset.style = new Cesium.Cesium3DTileStyle(
                extras.ion.defaultStyle
              )
            }
          } catch (error) {
            console.log(error)
          }
        })()
      }
      tilesetload()
    },
    qingchuModel() {
      // 移除cesium
      this.viewerModel.destroy()
    },
    initMap() {
      mapboxgl.accessToken =
        'pk.eyJ1IjoienlmcXdlciIsImEiOiJjbDRubDZiMWkxOGN2M2NseHVjY3JiaXIxIn0.ux9H0FCeCF_Ns6YjfZvVDA'
      this.map = new mapboxgl.Map({
        container: 'map', // container id
        // zoom: 7.237539085457299,
        zoom: 15, // starting zoom
        minZoom: 4,
        maxZoom: 21,
        dragRotate: false, //禁用地图的拖拽旋转功能
        touchRotate: false, //禁用地图的触控旋转功能
        style: {
          version: 8,
          sources: {
            // -------电子地图--------
            'img-tiles': {
              type: 'raster',
              tiles: [
                'https://t0.tianditu.gov.cn/vec_w/wmts?tk=db282a7eb175c9718382ac77b5819630&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'
              ],
              tileSize: 256
            },
            'img-label-tiles': {
              type: 'raster',
              tiles: [
                'https://t0.tianditu.gov.cn/cva_w/wmts?tk=db282a7eb175c9718382ac77b5819630&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'
              ],
              tileSize: 256
            },

            // 影像图
            'img-tdt-yx': {
              type: 'raster',
              tiles: [
                'https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=db282a7eb175c9718382ac77b5819630'
              ],
              tileSize: 256
            },
            'img-tdt-yx-zj': {
              type: 'raster',
              tiles: [
                'https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=db282a7eb175c9718382ac77b5819630'
              ],
              tileSize: 256
            }
          },

          layers: [
            {
              id: 'img-tiles',
              type: 'raster',
              source: 'img-tiles',
              minzoom: 0,
              maxzoom: 20,
              layout: {
                visibility: 'none'
              }
            },
            {
              id: 'img-label-tiles',
              type: 'raster',
              source: 'img-label-tiles',
              minzoom: 0,
              maxzoom: 20,
              layout: {
                visibility: 'none'
              }
            },
            {
              id: 'img-tdt-yx',
              type: 'raster',
              source: 'img-tdt-yx',
              minzoom: 0,
              maxzoom: 20,
              layout: {
                visibility: 'visible'
              }
            },
            {
              id: 'img-tdt-yx-zj',
              type: 'raster',
              source: 'img-tdt-yx-zj',
              minzoom: 0,
              maxzoom: 20,
              layout: {
                visibility: 'visible'
              }
            }
          ],
          glyphs: 'mapbox://styles/zyfqwer/{fontstack}/{range}.pbf'
        },
        // center: [120.0, 30.28]
        center: [119.86318622359217, 30.39968176468186]

        // center: [106.20380467583857, 37.48030824256634],
      })

      this.map.addControl(new mapboxgl.NavigationControl())
      this.$store.commit('set_map', this.map)
      this.map.on('style.load', () => {
        // 加载模拟耕地
        this.getGengDiArea()
        this.map.on('click', (e) => {
          const list = this.map.queryRenderedFeatures([e.point.x, e.point.y])
          console.log(e, '地图坐标信息')
        })
      })
      window.map = this.map

      // const imagePath =
      //   process.env.VUE_APP_BASE_URL +
      //   '/v1/file/view?fileId=' +
      //   this.mapImage.id
      // console.log(imagePath, 'hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')
      // console.log(this.mapImage, 'qqqqqqqqqqqqqqqqqqqqqq')
      // this.map.loadImage(imagePath, (error, image) => {
      //   if (error) throw error
      //   map.addImage('iconImage', image)
      // 图标位置坐标
      // let features = [
      //   {
      //     type: "Feature",
      //     geometry: {
      //       type: "Point",
      //       coordinates: [120.0, 30.18],
      //     },
      //   },
      //   {
      //     type: "Feature",
      //     geometry: {
      //       type: "Point",
      //       coordinates: [120.0, 30.18],
      //     },
      //   },
      // ];
      // })

      // map.loadImage(imagePath, (error, image) => {
      //   if (error) throw error;
      //   map.addImage("iconImage", image);
      // 图标位置坐标
      // let features = [
      //   {
      //     type: "Feature",
      //     geometry: {
      //       type: "Point",
      //       coordinates: [120.0, 30.18],
      //     },
      //   },
      //   {
      //     type: "Feature",
      //     geometry: {
      //       type: "Point",
      //       coordinates: [120.0, 30.18],
      //     },
      //   },
      // ];

      //   map.addSource('iconImage', {
      //     type: 'geojson',
      //     data: {
      //       type: 'FeatureCollection',
      //       features: [
      //         {
      //           type: 'Feature',
      //           geometry: {
      //             type: 'Point',
      //             coordinates: [120.0, 30.18]
      //           }
      //         },
      //         {
      //           type: 'Feature',
      //           geometry: {
      //             type: 'Point',
      //             coordinates: [120.0, 30.18]
      //           }
      //         }
      //       ]
      //     }
      //   })
      //   map.addLayer({
      //     id: 'iconImage',
      //     type: 'symbol',
      //     source: 'iconImage', // 对应addSource第一个参数名字
      //     layout: {
      //       'icon-image': 'iconImage', // 对应addImage()第一个参数名字
      //       // "icon-size": 0.5, //图标的大小
      //       'icon-size': 0.25 //图标的大小
      //       // "icon-size": [1, 1], //图标的大小
      //     }
      //   })
      // })
    },
    getGengDiArea() {
      // 耕地面积
      let gengdiArr = []
      gdNew.features.forEach((item) => {
        gengdiArr.push({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: item.geometry.coordinates
          }
        })
      })

      // 添加一个绿色的多边形
      this.map.addSource('polygons', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: gengdiArr
        }
      })
      // this.map.addLayer({
      //   id: 'polygon-layer',
      //   type: 'fill',
      //   source: 'polygons',
      //   layout: {},
      //   paint: {
      //     'fill-color': '#00FF00',
      //     'fill-opacity': 0.3
      //   }
      // })
      this.map.addLayer({
        id: 'polygon-line',
        type: 'line',
        source: 'polygons',
        paint: {
          'line-color': 'red', // 多边形的填充颜色
          'line-width': 2
        },
        layout: {}
      })
    }
  }
}
</script>
<style scoped lang="scss">
.mapbox {
  position: relative;
  #map {
    width: 100%;
    height: 100vh;
  }
}
.threeModel {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 15px;
  right: 220px;
}
::v-deep .mapboxgl-ctrl-top-right {
  top: 85px;
}
// ::v-deep .mapboxgl-ctrl-top-right {
//   // width: 60px;
//   // height: 150px;
//   position: absolute;
//   right: 2%;
//   top: 70%;
//   height: 180px;
//   // top: 1%;
//   z-index: 1000;
//   // background-color: #fff;
//   .mapboxgl-ctrl-group {
//     border-radius: 4px;
//     // background: #fff;
//     .mapboxgl-ctrl-zoom-in {
//       background-color: transparent;
//       border: 0;
//       box-sizing: border-box;
//       cursor: pointer;
//       display: block;
//       height: 30px;
//       outline: none;
//       overflow: hidden;
//       padding: 0;
//       width: 30px;
//       border-bottom: 1px solid #ccc;

//       .mapboxgl-ctrl-icon {
//         background-position: 50%;
//         background-repeat: no-repeat;
//         display: block;
//         height: 100%;
//         width: 100%;
//         background-image: url("@/assets/images/放大.png");
//         background-size: 100% 100%;
//       }
//     }
//     .mapboxgl-ctrl-zoom-out {
//       background-color: transparent;
//       border: 0;
//       box-sizing: border-box;
//       cursor: pointer;
//       display: block;
//       height: 30px;
//       outline: none;
//       overflow: hidden;
//       padding: 0;
//       width: 30px;
//       border-bottom: 1px solid #ccc;

//       .mapboxgl-ctrl-icon {
//         background-position: 50%;
//         background-repeat: no-repeat;
//         display: block;
//         height: 100%;
//         width: 100%;
//         background-image: url("@/assets/images/缩小.png");
//         background-size: 100% 100%;
//       }
//     }
//     .mapboxgl-ctrl-compass {
//       background-color: transparent;
//       border: 0;
//       box-sizing: border-box;
//       cursor: pointer;
//       display: block;
//       height: 30px;
//       outline: none;
//       overflow: hidden;
//       padding: 0;
//       width: 30px;

//       .mapboxgl-ctrl-icon {
//         background-position: 50%;
//         background-repeat: no-repeat;
//         display: block;
//         height: 100%;
//         width: 100%;
//         background-image: url("@/assets/images/指针.png");
//         background-size: 100% 100%;
//       }
//     }
//   }
// }
// ::v-deep .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
//   background: url("@/assets/images/放大.png");
// }

// ::v-deep .mapboxgl-ctrl button .mapboxgl-ctrl-icon {
//   background-position: 50%;
//   background-repeat: no-repeat;
//   display: block;
//   height: 100%;
//   width: 100%;
// }
</style>
