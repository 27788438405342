var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('div',{staticClass:"milai-app"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"header-menu"},_vm._l((_vm.menuList),function(menu,index){return _c('div',{key:index,staticClass:"header-menu-item",class:{ active: menu.path == _vm.$route.path },on:{"click":function($event){return _vm.routeMenu(index, menu)}}},[_vm._v(" "+_vm._s(menu.name)+" ")])}),0),_c('div',{staticClass:"header-user"},[_vm._m(1),_c('div',{staticClass:"header-user-info"},[_vm._m(2),_c('div',{staticClass:"userName"},[_vm._v("BID:"+_vm._s(_vm.bindCode))]),_c('div',{staticClass:"out",on:{"click":_vm.logout}},[_c('img',{staticStyle:{"height":"100%","width":"100%"},attrs:{"src":require("../assets/images/header/out.png"),"alt":""}})])])])]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"main-container"},[_c('div',{class:_vm.$route.name === 'Live' ||
            _vm.$route.name === 'Model' ||
            _vm.$route.name === 'Label' ||
            _vm.$route.name === 'Device'
              ? 'main-container-list_noMap'
              : 'main-container-list'},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(
            _vm.$route.name === 'Task' ||
            _vm.$route.name === 'Image' ||
            _vm.$route.name === 'Bound' ||
            _vm.$route.name === 'Wayline'
          )?_c('div',{staticClass:"map"},[_c('publicMap')],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-left-logo"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/images/header/logo_test.png"),"alt":"","srcset":""}})]),_c('div',{staticClass:"header-left-name"},[_vm._v("机器人巡检平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-user-help"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/header/help.png"),"alt":""}})]),_c('div',{staticClass:"helpName"},[_vm._v("帮助")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoPhoto"},[_c('img',{staticClass:"user-profile",attrs:{"src":require("../assets/images/avatar.png"),"alt":""}})])
}]

export { render, staticRenderFns }