import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    map: null,
  },
  getters: {},
  mutations: {
    set_map(state, val) {
      state.map = val;
    },
  },
  actions: {},
  modules: {},
});

export default store;
