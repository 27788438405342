import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './permissions'
import store from './store'
import ace from 'ace-builds'
import drag from './utils/drag'
// import jquery from 'jquery'
import './assets/styles/index.scss'
// Vue.prototype.$ = jquery
import VueAMap from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'
import Antd from 'ant-design-vue' //增加
import 'ant-design-vue/dist/antd.css'
import VConsole from 'vconsole'
import VueCookies from 'vue-cookies'
// const vConsole = new VConsole()
// Vue.use(vConsole)
Vue.use(VueCookies)

Vue.use(ace)
Vue.use(VueAMap)
Vue.use(Antd)
Vue.config.productionTip = false
VueAMap.initAMapApiLoader({
  key: '292f0b8f5931849b22a9f8260dbd4271'
})
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
