<template>
  <div class="layout">
    <div class="milai-app">
      <div class="header">
        <div class="header-left">
          <div class="header-left-logo">
            <img style="width: 100%; height: 100%" src="@/assets/images/header/logo_test.png" alt="" srcset="" />
          </div>
          <div class="header-left-name">机器人巡检平台</div>
        </div>
        <div class="header-menu">
          <div v-for="(menu, index) in menuList" class="header-menu-item" :class="{ active: menu.path == $route.path }" @click="routeMenu(index, menu)" :key="index">
            {{ menu.name }}
          </div>
        </div>
        <div class="header-user">
          <div class="header-user-help">
            <div class="icon">
              <img src="@/assets/images/header/help.png" alt="" />
            </div>
            <div class="helpName">帮助</div>
          </div>
          <div class="header-user-info">
            <div class="infoPhoto">
              <img class="user-profile" src="../assets/images/avatar.png" alt="" />
            </div>
            <div class="userName">BID:{{ bindCode }}</div>
            <div class="out" @click="logout">
              <img style="height: 100%; width: 100%" src="../assets/images/header/out.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="main-container">
          <div :class="
              $route.name === 'Live' ||
              $route.name === 'Model' ||
              $route.name === 'Label' ||
              $route.name === 'Device'
                ? 'main-container-list_noMap'
                : 'main-container-list'
            ">
            <keep-alive><router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </div>
          <div class="map" v-if="
              $route.name === 'Task' ||
              $route.name === 'Image' ||
              $route.name === 'Bound' ||
              $route.name === 'Wayline'
            ">
            <publicMap></publicMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'
import publicMap from '@/components/publicTianMap/index.vue'

import 'mapbox-gl/dist/mapbox-gl.css'
import { clearMap } from '@/utils/layer'
import { mapState } from 'vuex'
import { getBindCode } from '@/utils/auth'
export default {
  name: 'Layout',
  components: {
    publicMap
  },
  data() {
    return {
      indexOf: 0,
      bindCode: '',
      // menuIndex: 0,
      avatar: '',
      menuList: [
        // {
        //   path: '/home',
        //   name: '系统'
        // },
        // {
        //   path: '/pred',
        //   name: '图像预测'
        // },
        {
          path: '/live',
          name: '工作空间'
        },
        // {
        //   path: '/home',
        //   name: '任务'
        // },
        // {
        //   path: '/image',
        //   name: '图片'
        // },
        // {
        //   path: '/bound',
        //   name: '图斑'
        // },
        // {
        //   path: '/wayline',
        //   name: '航线'
        // },
        // {
        //   path: '/model',
        //   name: '模型'
        // },
        // {
        //   path: '/label',
        //   name: '标签'
        // },
        // {
        //   path: '/draw',
        //   name: '图像绘制'
        // },

        // {
        //   path: '/plan',
        //   name: '任务计划'
        // },

        // // {
        // //   path: '/workspace',
        // //   name: '工作空间'
        // // },
        // {
        //   path: '/device',
        //   name: '设备'
        // }
        // {
        //   path: '/carLive',
        //   name: '车直播'
        // }

        // {
        //   path: '/test',
        //   name: '测试'
        // },
      ]
    }
  },
  computed: {
    ...mapState(['map']),
    map() {
      return this.$store.state.map
    }
  },
  mounted() {
    this.bindCode = getBindCode()
    // console.log(this.$route, '000pp')
    this.init()
    // console.log(this.$route.name, '000')
  },
  beforeDestroy() { },
  methods: {
    outHome() {
      this.$cookies.remove('access-token')
      this.$router.push('/login')
    },
    init() {
      this.avatar = this.$store.getters.avatar
      this.indexOf = 99
      // console.log(this.avatar)
    },

    routeMenu(index, item) {
      if (index != this.indexOf || Object.keys(this.$route.query).length > 0) {
        this.indexOf = index

        if (item.path === this.$route.path) return
        this.$router.push(item.path)
        // 清除地图上叠加的资源
        if (
          this.$route.name === 'Task' ||
          this.$route.name === 'Image' ||
          this.$route.name === 'Bound' ||
          this.$route.name === 'Wayline'
        ) {
          // this.map.flyTo({
          //   center: [120.0, 30.28],
          //   zoom: 11
          // })
          clearMap(
            this.map,
            [
              'img-tiles',
              'img-label-tiles',
              'img-tdt-yx',
              'img-tdt-yx-zj',
              'polygons'
            ],
            [
              'img-tiles',
              'img-label-tiles',
              'img-tdt-yx',
              'img-tdt-yx-zj',
              'polygon-line'
            ]
          )
          if (this.$store.state.markerList.length > 0) {
            let markerArry = this.$store.state.markerList
            markerArry.forEach((marker) => {
              marker.remove()
            })
            markerArry = []
            this.$store.commit('set_marker', markerArry)
          }
        }
      }
    },
    logout() {
      this.$store.dispatch('user/logout')
    }
  }
  // watch: {
  //   '$route.name': {
  //     handler(val) {
  //       console.log(val, 'header')
  //     }
  //   }
  //   // menuIndex(newValue) {
  //   //   console.log('laty menuIndex', newValue)
  //   //   this.$router.push(this.menuList[newValue].path);
  //   // }
  // }
}
</script>
<style lang="less" scoped>
.main-container-list_noMap {
  position: absolute;
  top: 75px;
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
}

.active {
  color: #fff;
  border-bottom: 2px solid #fff;
}

.milai-app {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 75px;
  display: flex;
  background-color: #282b33;
  align-items: center;
  color: #6f6f64;
  font-size: 20px;
  position: absolute;
  z-index: 100;

  &-left {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;

    &-logo {
      width: 55px;
      height: 50px;
    }

    &-name {
      color: #fff;
      padding-left: 10px;
      // overflow: hidden;/
      font-size: 25px;
      white-space: nowrap;
    }
  }

  &-menu {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 19px;

    &-item {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 10px;
      // width: 200px;
      // margin-left: 100px;
    }

    &-item:hover {
      color: #fff;
      // border-bottom: 2px solid #fff;
      // width: 200px;
      // margin-left: 100px;
    }
  }

  &-user {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: right;

    &-help {
      display: flex;
      align-items: center;

      .icon {
        width: 20px;
        height: 20px;
        line-height: 18px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .helpName {
        font-size: 15px;
        margin-left: 2px;
      }
    }

    &-info {
      // width: 60%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 10px;

      .infoPhoto {
        .user-profile {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid #fff;
        }
      }

      .userName {
        font-size: 15px;
        margin-left: 10px;
      }

      .out {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--theme-bg-color);
}

.main-container {
  width: 100%;
  height: 100%;

  &-list {
    position: absolute;
    top: 75px;
    width: 590px;
    height: 100%;
  }
}
</style>
