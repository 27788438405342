<template>
  <div class="mapCheck">
    <div v-for="(item, index) in options" :key="index" class="mapActive">
      <el-button
        type="primary"
        size="mini"
        @click="mapChange(item.layer, index)"
        >{{ item.label }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mapboxgl from 'mapbox-gl'

export default {
  name: 'index',
  data() {
    return {
      value: null,
      indexof: 0
      // map: null,
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['map'])
  },
  methods: {
    mapChange(value, index) {
      this.indexof = index

      this.options.forEach((item) => {
        item.layer.forEach((lay) => {
          this.map.setLayoutProperty(lay, 'visibility', 'none')
        })
      })
      value.forEach((item) => {
        this.map.setLayoutProperty(item, 'visibility', 'visible')
      })
    }
  }
}
</script>

<style scoped lang="less">
.mapCheck {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0%;
  bottom: 2%;
  width: 200px;
  z-index: 1;
  .mapActive {
    margin-right: 10px;
  }
  // .zcCheck {
  //   width: 60px;
  //   height: 30px;
  //   // padding: 2px 2px 0 2px;
  //   // background-color: #fff;
  //   text-align: center;
  //   margin-right: 10px;
  //   font-size: 14px;
  // }
  // .xzCheck {
  //   width: 60px;
  //   height: 30px;

  //   // border: 3px solid #3385ff;
  //   // padding: 2px 2px 0 2px;
  //   // background-color: #fff;
  //   text-align: center;
  //   margin-right: 10px;
  //   font-size: 14px;
  // }
}
::v-deep.el-select {
  width: 100px;
  height: 30px;

  .el-input {
    width: 195%;
    .el-input__inner {
      background: #694d04;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 30px;
      border: 0px;
      text-align: center;
    }
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-icon-arrow-up:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 10px;
          // background: url('@/assets/images/地图箭头.png') no-repeat 0px 0px;
          background-size: 100% 100%;
          // transform: rotate(45deg);
        }
      }
    }
  }
  .el-select-dropdown {
    background: #694d04;
    border: 0px;
    .el-select-dropdown__item {
      span {
        color: #fff;
      }
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #4f7ab0;
    }
    .popper__arrow {
      display: none;
    }
    .el-scrollbar__view {
      padding: 0;
    }
  }
}
</style>
