import Vue from 'vue'
import Router from 'vue-router'
import { ERouterName } from '@/types/index'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
// import ParentView from '@/components/ParentView'

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'live',
    children: [
      // {
      //     path: '/home',
      //     component: (resolve) => require(['@/views/home/index'], resolve),
      //     hidden: false,
      // },
      {
        path: '/pred',
        component: resolve => require(['@/views/pred/index'], resolve),
        name: 'Pred'
      },
      {
        path: '/draw',
        component: resolve => require(['@/views/draw/index'], resolve),
        name: 'Draw'
      },
      {
        path: '/amap',
        component: resolve => require(['@/views/amap/index'], resolve),
        name: 'Amap'
      },
      {
        path: '/workspace',
        component: resolve => require(['@/views/workspace/index'], resolve),
        name: 'Workspace'
      },
      {
        path: '/device',
        component: resolve => require(['@/views/device/index'], resolve),
        name: 'Device'
      },
      {
        path: '/model',
        component: resolve => require(['@/views/model/index'], resolve),
        name: 'Model'
      },
      {
        path: '/label',
        component: resolve => require(['@/views/label/index'], resolve),
        name: 'Label'
      },
      {
        path: '/home',
        component: resolve => require(['@/views/task/index'], resolve),
        name: 'Task'
      },

      {
        path: '/wayline',
        component: resolve => require(['@/views/wayline/index'], resolve),
        name: 'Wayline'
      },

      {
        path: '/plan',
        component: resolve => require(['@/views/plan/index'], resolve),
        name: 'Plan'
      },
      {
        path: '/image',
        component: resolve => require(['@/views/image/index'], resolve),
        name: 'Image'
      },
      // {
      //   path: '/photo',
      //   component: (resolve) => require(['@/views/photo/index'], resolve),
      //   name: 'Photo'
      // },
      {
        path: '/bound',
        component: resolve => require(['@/views/bound/index'], resolve),
        name: 'Bound'
      },
      {
        path: '/test',
        component: resolve => require(['@/views/test/index'], resolve),
        name: 'Test'
      },
      {
        path: '/live',
        component: resolve => require(['@/views/live/index'], resolve),
        name: 'Live',
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/carLive',
        component: resolve => require(['@/views/car/index.vue'], resolve),
        name: 'CarLive',
        meta: {
          keepAlive: true
        }
      }
    ]
  },

  // pilot
  {
    path: '/' + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import('@/views/page-pilot/pilot-index.vue')
  },
  {
    path: '/' + ERouterName.PILOT_HOME,
    component: () => import('@/views/page-pilot/pilot-home.vue')
  },
  {
    path: '/' + ERouterName.PILOT_MEDIA,
    component: () => import('@/views/page-pilot/pilot-media.vue')
  },
  {
    path: '/' + ERouterName.PILOT_LIVESHARE,
    component: () => import('@/views/page-pilot/pilot-liveshare.vue')
  },
  {
    path: '/' + ERouterName.PILOT_BIND,
    component: () => import('@/views/page-pilot/pilot-bind.vue')
  },
  {
    path: '/' + ERouterName.PILOT_DEVICE_BIND,
    component: () => import('@/views/page-pilot/pilot-device-bind.vue')
  },
  {
    path: '/control',
    component: resolve => require(['@/views/control/index'], resolve),
    name: 'Control'
  },
  {
    path: '/login',
    component: resolve => require(['@/views/login/index'], resolve),
    name: 'Login'
  },
  {
    path: '/404',
    component: resolve => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: resolve => require(['@/views/error/401'], resolve),
    hidden: true
  },

  { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  // scrollBehavior: () => ({ y: 0 }),
  // base: '/',
  routes: constantRoutes
})
