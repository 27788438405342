export class Icon {
  constructor(map, iconName, sourceId, layerId, collean, layoutOptions) {
    this.map = map
    this.iconName = iconName
    this.sourceId = sourceId
    this.layerId = layerId
    this.collean = collean
    this.layoutOptions = layoutOptions
  }
  init() {
    const url = require(`@/assets/images/mapIcon/${this.iconName}.png`)
    this.map.loadImage(url, (error, image) => {
      if (error) throw error
      this.map.addImage(this.iconName, image)
    })
    this.map.addSource(this.sourceId, {
      type: 'geojson',
      data: this.collean
    })
    this.map.addLayer({
      id: this.layerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'icon-image': this.iconName,
        'icon-size': 0.4,
        'icon-allow-overlap': true, // 是否允许图标重叠（可选，默认值为 false。当值为 true 时，图标即使和其他符号触碰也会显示）
        'icon-ignore-placement': true, // 是否忽略图标位置（可选，默认值为 false。当值为 true 时，其他符号即使与此图标触碰也会显示）
        'icon-optional': true, // 图标是否
        'symbol-avoid-edges': true,
        'text-allow-overlap': true,
        // "text-field": ["get", "name"],
        'text-font': ['huawei'],
        ...this.layoutOptions
      },
      paint: {
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'text-halo-color': 'red',
        'text-color': '#fff',
        'text-translate': [0, 38],
        'text-translate-anchor': 'viewport'
      }
    })
  }
}
export function clearMap(map, reserveSource, reserveLayer) {
  console.log(map)
  const layer = map.getStyle().layers
  const source = map.getStyle().sources

  layer.forEach((element) => {
    const index = reserveLayer.indexOf(element.id)

    if (index === -1) {
      map.removeLayer(element.id)
    }
  })

  for (let key in source) {
    let index = reserveSource.indexOf(key)
    if (index === -1) {
      map.removeSource(key)
    }
  }
}
