import axios from 'axios'
import router from '@/router'
// import { MessageBox, Message } from 'element-ui'
import { notifyError, notifySuccess } from '@/utils/notify'
import store from '@/store'
import { getToken, removeToken, getRuoYiToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  // url = base url + request url
  baseURL: process.env.VUE_APP_CLOUD_BASE_URL
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['x-auth-token'] = getToken()
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data

    // if (res.code == 4030) {
    //   removeToken()
    //   router.push('/login')
    // }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code == 200 || res.code == 0) {
      return res
    }
    // notifyError(res.message || res.msg || 'Error')
    console.log(res.message)
    return res
  },
  (error) => {
    notifyError(error.message)
    return Promise.reject(error)
  }
)

export default service
