<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
body {
  margin: 0;
}

html,
body,
#app {
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;

  /*background:rgba(38, 24, 49,.6);*/
}

#nprogress .bar {
  background: var(--logo-color) !important;
}
</style>
<style lang="less" scoped></style>
