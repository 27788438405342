import request from '@/utils/cloud_request'

export function cloudLogin(data) {
  return request({
    url: `/manage/api/v1/login`,
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: `/user/info`,
    method: 'get'
  })
}

export function cloudRuoyiLogin(data) {
  return request({
    url: `/user/login`,
    method: 'post',
    data
  })
}

export function getImage(data) {
  return request({
    url: `/resource/sys_file/list`,
    method: 'post',
    data
  })
}

export function getWorkSpace(data) {
  return request({
    url: `/resource/sys_workspace/list`,
    method: 'post',
    data
  })
}

export function udWorkSpace(data) {
  return request({
    url: `/resource/sys_workspace/saveorupdate`,
    method: 'post',
    data
  })
}

export function getWayLine_file(data) {
  return request({
    url: `/resource/wayline_file/list`,
    method: 'post',
    data
  })
}
export function udWayLine_file(data) {
  return request({
    url: `/resource/wayline_file/saveorupdate`,
    method: 'post',
    data
  })
}

export function getManage_device(data) {
  return request({
    url: `/resource/manage_device/list`,
    method: 'post',
    data
  })
}
export function udManage_device(data) {
  return request({
    url: `/resource/manage_device/saveorupdate`,
    method: 'post',
    data
  })
}

// 若依临安登录
export async function getRuoyiToken(params = {}) {
  // return request.get(`/user/token/login`, params);
  return request({
    method: 'post',
    url: 'http://47.104.83.88:48080/admin-api/system/auth/login',
    data: params
  })
}
// 获取临安自己的userinfo
// export async function getUserInfo_linan(params = {}) {
//   return (await request.get(`http://192.168.3.210:48080/warning/user/info`))
//     .data
// }
