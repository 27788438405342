import Cookies from 'js-cookie'

const TokenKey = 'access-token'

const WidKey = 'workspaceId'
const CodeKey = 'bindcode'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getWorkspaceId() {
  return Cookies.get(WidKey)
}

export function setWorkspaceId(wid) {
  return Cookies.set(WidKey, wid)
}
export function setBindCode(code) {
  return Cookies.set(CodeKey, code)
}

export function getBindCode() {
  return Cookies.get(CodeKey)
}
export function getRuoYiToken() {
  return window.localStorage.getItem('ruoyi_token')
}
