import { Notification } from 'element-ui';
import { message, Popconfirm } from 'ant-design-vue'
export function notifyInof(msg) {
    message.info(msg)
}

export function notifySuccess(msg) {
    message.success(msg)
}

export function notifyError(msg) {
    message.error(msg)
}

export function notifyWarning(msg) {
    message.warning(msg)
}
